import React, { useContext } from "react"
import {
  Flex,
  Box,
  Heading,
  Button,
  Stack,
  useColorMode,
} from "@chakra-ui/react"
import Img from "gatsby-image"
import { LocalizationContext } from "../context/LocalizationContext"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { slugify } from "../utils/slugify"

export default ({
  image,
  subTitle,
  title,
  excerpt,
  slug,
  lang,
  reversed = false,
}) => {
  const { translate } = useContext(LocalizationContext)
  const { colorMode } = useColorMode()
  const locale =
    lang.toLowerCase() === "en" || lang.toLowerCase() === "zh"
      ? "/"
      : `/${lang.toLowerCase()}/`
  return (
    <Flex sx={{ flexWrap: ["wrap", null, null, "nowrap"], mb: [12, null, 16] }}>
      <Box
        as={Img}
        fluid={{ ...image.childImageSharp.fluid, aspectRatio: 4 / 3 }}
        backgroundColor={image.colors.darkVibrant}
        alt={image.altText}
        sx={{
          width: ["100%", null, null, "55%"],
          order: reversed ? [0, null, null, 1] : 0,
          mb: 4,
        }}
      />
      <Stack
        spacing={4}
        width={["100%", null, null, "45%"]}
        justifyContent="center"
        order={reversed ? [1, null, null, 0] : 1}
        pl={reversed ? 0 : [0, null, null, 8]}
        pr={reversed ? [0, null, null, 8] : 0}
      >
        <Box>
          <Heading
            size="md"
            mb={2}
            color={
              colorMode === "dark"
                ? image.colors.lightVibrant
                : image.colors.darkVibrant
            }
          >
            {subTitle}
          </Heading>
          <Heading size="lg" as="h3">
            {title}
          </Heading>
        </Box>
        <Box>{excerpt}</Box>
        <Stack direction="row">
          <Button as={Link} to={`${locale}${slug}/${slugify(subTitle)}/`}>
            {translate("tour.readMore")}
          </Button>
          <Button as={AnchorLink} href="#contact" variant="outline">
            {translate("tour.contact")}
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}
