import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Stack, Container, Box } from "@chakra-ui/react"
import { LocalizationContext } from "../context/LocalizationContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TourHighlight from "../components/tourHighlight"

const IndexPage = ({ data, pageContext }) => {
  const tour = data.wpTour
  const language = pageContext.language
  const { setLanguage } = useContext(LocalizationContext)
  setLanguage(language)
  let reversed = false
  return (
    <Layout language={language} overlayHeader={true}>
      <SEO
        title={tour.title}
        lang={language}
        alternates={pageContext.alternates}
      />
      <Hero
        image={tour.featuredImage.node.localFile}
        title={tour.title}
        subTitle={tour.tourBuilder.subtitle}
      />
      <Container maxWidth="5xl" mt={[12, 24]}>
        <Stack spacing={[12, 24]}>
          <Box
            fontSize={{ md: "lg", lg: "xl" }}
            dangerouslySetInnerHTML={{ __html: tour.tourBuilder.description }}
          />
          <Box>
            {tour.tourBuilder.schedule &&
              tour.tourBuilder.schedule.map((schedule, i) => {
                if (i % 2 === 0 && reversed === false) reversed = true
                else reversed = false
                return (
                  <TourHighlight
                    image={schedule.image.localFile}
                    subTitle={schedule.title}
                    title={schedule.subtitle}
                    reversed={reversed}
                    excerpt={schedule.excerpt}
                    slug={tour.slug}
                    lang={tour.language.code}
                    key={i}
                  />
                )
              })}
          </Box>
          <Box>
            <Box>{tour.tourBuilder.price}</Box>
            <Box>{tour.tourBuilder.dates}</Box>
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($id: String) {
    wpTour(id: { eq: $id }) {
      id
      slug
      title
      language {
        code
      }
      tourBuilder {
        subtitle
        description
        price
        dates
        schedule {
          body
          excerpt
          fieldGroupName
          subtitle
          title
          image {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 60) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
              colors {
                vibrant
                darkVibrant
                lightVibrant
                muted
                darkMuted
                lightMuted
              }
            }
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
            colors {
              vibrant
              darkVibrant
              lightVibrant
              muted
              darkMuted
              lightMuted
            }
          }
        }
      }
    }
  }
`
